<template>
	<div class="container">
	<Header :flag="flag" />
	<div class="banner">
	</div>
	<div class="main">
		<div id="api" class="api-main">
			<el-row>
			  <el-col :span="4"><div class="api_menu_l grid-content bg-purple">
				  <el-menu
					:default-active="menuDefaultActive"
					background-color="#ffffff"
					class="el-menu-vertical-demo"
					@select="handleSelect"
					@open="handleOpen"
					@close="handleClose">
					<el-menu-item index="type-0">
					  <i class="el-icon-menu"></i>
					  <span slot="title">接口目录</span>
					</el-menu-item>
					<el-submenu  v-for="(item,index) in typeData" :value="item.id+''" :index="'type-'+item.id">
					  <template slot="title">
						<i class="el-icon-location"></i>
						<span>{{item.name}}({{item.apiList.length}})</span>
					  </template>
					  <el-menu-item-group>
						<el-menu-item v-for="(item1,index1) in item.apiList" :value="item1.id+''" :index="item1.id+''" @click="getApiData(item1.id)">{{item1.apiName}}</el-menu-item>
					  </el-menu-item-group>
					</el-submenu>
				  </el-menu>
			  </div></el-col>
			  <el-col :span="20"><div class="api_menu_r grid-content bg-purple-light">
				<div class="el-card api_detail_card is-always-shadow" v-if="menuDefaultActive=='type-0'?false:true">
					<div class="api-detail-title flex-row-between">
						<div class="flex-row">
							<div class="api-img">
								<img src="../assets/img/api-icon.png" style="width: 100%; height: 100%;" />
							</div>
							<div class="api-brief flex-col">
								<span class="api-name">{{data.apiName}}</span>
								<span class="api-des">版本：{{data.version}}</span>
								<span class="api-des">描述: {{data.remark}}</span>
							</div>
						</div>
						<!-- <a href="#" style="margin: auto 0px;">
							<div class="api-button flex-col">
								<span>调试API</span>
							</div>
						</a> -->
					</div>
					<div class="api_detail_line"></div>
					<div class="api-body">
						<el-tabs v-model="activeName" @tab-click="handleClick">
						  <el-tab-pane label="API文档" name="first">
							  <div class="tab-card-list flex-col">
								  <div class="tab-card-list-title flex-row">
									  <div class="card_detail_icon">
										 <img src="../assets/img/document-icon.png" />
									  </div>
									  <div class="card_detail_name">API文档信息</div>
								  </div>
								  <div class="tab-card-list-detail">
									  <div class="api-detail-info">接口地址：{{data.apiUrl}}</div>
									  <div class="api-detail-info">请求方式：{{data.requestMethod}}</div>
									  <div class="api-detail-info">返回格式：{{data.returnFormat}}</div>
									  <div class="api-detail-info">请求示例：{{data.requestExample}}</div>
									  <div class="api-detail-info">接口说明：{{data.remark}}</div>
								  </div>
							  </div>
							  <div class="tab-card-list flex-col">
								    <div class="tab-card-list-title flex-row">
									  <div class="card_detail_icon">
										 <img src="../assets/img/identify.png" />
									  </div>
									  <div class="card_detail_name">授权认证方式</div>
									</div>
									<div class="tab-card-list-detail">
										<div class="api-detail-info">默认方式：需要添加请求header，键值为token</div>
										<div class="api-detail-info">代码示例：HttpGet get = new HttpGet(url); get.setHeader(“token”, token);</div>
									</div>
							 </div>
							  <div class="tab-card-list flex-col">
								<div class="tab-card-list-title flex-row">
								  <div class="card_detail_icon">
									 <img src="../assets/img/explanation.png" />
								  </div>
								  <div class="card_detail_name">请求参数说明</div>
								</div>
								<div class="tab-card-list-detail">
									<el-table
									  :data="data.apiReqParamsList"
									  style="width: 100%">
									  <el-table-column
										prop="name"
										label="名称"
										width="180">
									  </el-table-column>
									  <el-table-column
										prop="type"
										label="类型"
										width="180">
									  </el-table-column>
									  <el-table-column
										prop="required"
										label="必填">
									  </el-table-column>
									  <el-table-column
										prop="desc"
										label="描述">
									  </el-table-column>
									</el-table>
							    </div>
							 </div>
							 <div class="tab-card-list flex-col">
								 <div class="tab-card-list-title flex-row">
								   <div class="card_detail_icon">
								 	 <img src="../assets/img/return-back.png" />
								   </div>
								   <div class="card_detail_name">返回参数说明</div>
								 </div>
								 <div class="tab-card-list-detail">
									 <el-table
									   :data="data.apiResParamsList"
									   style="width: 100%">
									   <el-table-column
									 	prop="name"
									 	label="名称"
									 	width="180">
									   </el-table-column>
									   <el-table-column
									 	prop="type"
									 	label="类型"
									 	width="180">
									   </el-table-column>
									   <el-table-column
									 	prop="desc"
									 	label="描述">
									   </el-table-column>
									 </el-table>
								 </div>
							 </div>
							<div class="tab-card-list flex-col">
							  <div class="tab-card-list-title flex-row">
								  <div class="card_detail_icon">
									 <img src="../assets/img/json-icon.png" />
								  </div>
								  <div class="card_detail_name">JSON返回示例</div>
							  </div>
							  <div class="tab-card-list-detail">
								  <json-viewer :value="data.jsonExample==null?'':data.jsonExample" boxed copyable ></json-viewer>
							  </div>	
							</div>								
						  </el-tab-pane>
						  <!-- <el-tab-pane label="相关文档" name="second">相关文档</el-tab-pane> -->
						  <el-tab-pane label="响应代码" name="third">
							  <div class="tab-card-list flex-col">
								 <div class="tab-card-list-title flex-row">
								   <div class="card_detail_icon">
									 <img src="../assets/img/return-back.png" />
								   </div>
								   <div class="card_detail_name">响应代码</div>
								 </div>
								 <div class="tab-card-list-detail">
									 <el-table
									   :data="data.resApiCodesList"
									   style="width: 100%">
									   <el-table-column
										prop="retCode"
										label="响应码"
										width="180">
									   </el-table-column>
									   <el-table-column
										prop="retMsg"
										label="描述">
									   </el-table-column>
									 </el-table>
								 </div>
							  </div>
							  <div class="tab-card-list flex-col">
								 <div class="tab-card-list-title flex-row">
								   <div class="card_detail_icon">
									 <img src="../assets/img/return-back.png" />
								   </div>
								   <div class="card_detail_name">通用响应代码</div>
								 </div>
								 <div class="tab-card-list-detail">
									 <el-table
									   :data="data.genericCodesList"
									   style="width: 100%">
									   <el-table-column
										prop="retCode"
										label="响应码"
										width="180">
									   </el-table-column>
									   <el-table-column
										prop="retMsg"
										label="描述">
									   </el-table-column>
									 </el-table>
								 </div>
							  </div>
						  </el-tab-pane>
						</el-tabs>
					</div>
				</div>	
				<div class="el-card api-box-card is-always-shadow" v-if="menuDefaultActive=='type-0'?true:false">
					<div class="card-header">
						<span>全部服务</span>
					</div>
					<div class="card-main">
						<ul class="api-list">
							<li v-for="item in apiList">
								<div class="api-info">
									<span class="icon-box2">
										<img src="../assets/img/api-icon.png" style="width: 40px; padding: 5px; height: 40px;" />
									</span>
									<dl class="api-list-dl">
										<dt>
											{{item.apiName}}
										</dt>
										<dd>
											{{item.remark}}
										</dd>
									</dl>
								</div>
								<div class="api-button flex-col" @click="detail(item.id)">
									<span>查看详情</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			  </div>
			  </el-col>
			</el-row>
		</div>
	</div>

	<Footer />	
	</div>	
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import JsonViewer  from 'vue-json-viewer'
	export default {
		metaInfo: {
			title: 'API平台-全维度大数据',
			meta: [
			  {
				name: 'keywords',
				content: ''
			  },
			  {
				name: 'description',
				content: '广西南宁蓝莓网络科技有限公司-API平台'
			  }
			]
		},
		name: 'CloudApi',
		components: {
			Header,
			Footer,
			JsonViewer
		},
		data(){
			return{
				menuDefaultActive: 'type-0',
				flag:'solution',
				activeName: 'first',
				typeData:[],
				apiList:[],
				data:[],
			}
		},
		created() {
		    this.getTypeList();	
		},
		methods: {
			async getTypeList(){
			   await this.$http.getTypeList().then(res => {
					if(res.data!=null){
						this.typeData = res.data;
						if(this.$route.query.apiId!=null){
							console.log(this.$route.query.apiId);
							this.menuDefaultActive=this.$route.query.apiId;
							this.getApiData(this.$route.query.apiId);
						}else{
							this.getApiList();
						}
					}
				});
			},
			getApiList(){
				this.$http.getApiList({id:null}).then(res => {
					if(res.data!=null){
						this.apiList = res.data;
					}
				})
			},
			getApiData(id){
				this.$http.getApiBasic({id:id}).then(res => {
					if(res.data!=null){
						this.data = res.data;
						this.reqParamsData=res.data.apiReqParamsList;
						this.resParamsData=res.data.apiResParamsList;
						this.codeData=res.data.resApiCodesList;
						this.comCodeData=res.data.genericCodesList;
					}
				})
			},
			detail(id){
				this.menuDefaultActive=id+"";
				this.getApiData(id)
				// this.$router.push({path:'/cloudApi',name:'CloudApi',query:{apiId:id,t:Date.now()}});
			},
			handleSelect(key, keyPath){
				if(key=='type-0'){
					this.menuDefaultActive='type-0';
					this.getApiList();
				}else{
					if(key.indexOf('type-') ==-1){
						this.menuDefaultActive=key+"";
						this.getApiData(key);
					}
				}
			},
			handleOpen(key, keyPath) {
				console.log(key);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClick(tab, event) {
				console.log(tab, event);
			}
		}
	}	
</script>

<style scoped lang="less">
	.banner{
		 height: 90px;
		 background-color: royalblue;
	}
	.main{
		width: 1200px;
		margin: auto;
		margin-top: 40px;
		min-height: 600px;
		.flex-row {
			display: flex;
			flex-direction: row;
		}
		.flex-col {
			display: flex;
			flex-direction: column;
		}
		.api_menu_r{
			padding-left: 10px; 
			padding-right: 10px;
			.el-card{
				border: 1px solid #ebeef5;
				background-color: #fff;
				color: #303133;
				transition: .3s;
			}
			.is-always-shadow{
				box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
			}
			.api_detail_card{
				border-radius: 4px;
				overflow: hidden;
				.api-detail-title{
					background: #fff;
					padding: 20px;
					height: 100px;
					line-height: 100px;
					.api-img{
						width: 81px;
						height: 80px;
					}
					.api-brief {
						margin-left: 30px;
						width: 650px;
						.api-name {
							height: 40px;
							display: block;
							color: #303133;
							font-size: 18px;
							line-height: 40px;
							text-align: left;
						}
						 .api-des {
							height: 42px;
							display: block;
							color: #909399;
							font-size: 14px;
							line-height: 21px;
							text-align: left;
						}
					}
					
					.api-button {
						height: 40px;
						border-radius: 6px;
						background-color: #128bed;
						box-shadow: 0 6px 10px 0 rgb(18 139 237 / 20%);
						width: 94px;
						justify-content: center;
						align-items: center;
						margin: auto;
						cursor: pointer;
						span{
							color: white;
						}
					}
				}
				.flex-row-between{
					display: flex;
					flex-direction: row;
					justify-content: space-between;
				}
			}
			.api-box-card{
				.card-header{
					padding: 18px 20px;
					border-bottom: 1px solid #ebeef5;
					box-sizing: border-box;
				}
				.card-main{
					.api-list{
						background: #fff;
						li{
							padding: 10px 0;
							margin: 0 30px;
							border-bottom: 1px solid #e6e6e6;
							display: flex;
							justify-content: space-between;
							align-items: center;
							.api-info{
								width: 100%;
								display: flex;
								justify-content: space-between;
								align-items: center;
								.icon-box2{
									width: 50px!important;
									height: 50px!important;
									background: #f8f8fa;
									float: left;
									margin-right: 20px;
									position: relative;
									img{
										vertical-align: middle;
									}
								}
								dl{
									display: block;
									margin-block-start: 1em;
									margin-block-end: 1em;
									margin-inline-start: 0px;
									margin-inline-end: 0px;
								}
								.api-list-dl{
									flex: 1;
									dt{
										font-size: 17px;
										font-weight: 700;
										margin-bottom: 5px;
									}
									dd{
										color: #666;
										margin-bottom: 5px;
										font-size: 13px;
									}	
								}
							}
							.api-button{
								height: 40px;
								border-radius: 6px;
								background-color: #128bed;
								box-shadow: 0 6px 10px 0 rgb(18 139 237 / 20%);
								width: 94px;
								justify-content: center;
								align-items: center;
								margin: auto;
								cursor: pointer;
								span{
									color: white;
								}
							}
						}
					}
				}
			}
		}
		.api_detail_line{
			width: 100%;
			height: 2px;
			background-color: #f3faff;
		}
		.api-body{
			padding: 20px;
			.tab-card-list-title{
				height: 40px;
				margin-top: 20px;
				padding: 10px 20px;
				.card_detail_icon{
					width: 20px;
					height: 20px;
					margin-top: 9px;
					img{
						width: 20px;
						height: 20px;
					}
				}
				.card_detail_name{
					height: 40px;
					display: block;
					color: #303133;
					font-size: 16px;
					line-height: 40px;
					text-align: left;
					margin-left: 5px;
				}
			}
			.tab-card-list-detail{
				color: #909399;
				width: 100%;
				font-size: 14px;
				line-height: 28px;
				text-align: left;
				align-self: flex-start;
				    margin: 0 0 20px 20px;
				.api-detail-info{
					
				}
			}
		}
	}
</style>
